import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TestSeriesSection from './TestSeriesSection';
import ProgramSection from './ProgramSection';
import './Home.css';
import './WhyPregradSection.css';
import { motion } from 'framer-motion';
import Popup from './Popup';
import reading from './images/reading.png';
import check from './images/check.png';
import list from './images/list.png';
import clas from './images/clas.png';
import monitor from './images/monitor.png';
import enquireLogo from './images/enquireLogo.png';
import CountUp from 'react-countup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewCarousel from './ReviewCarousel';
import { Typography } from '@mui/material';
import CollaboratorsSection from './CollaboratorsSection';
import CarouselComponent from './CarouselComponent';
import ChatPopup from './ChatPopup';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What kind of career support will I be given?",
      answer: "This course provides comprehensive On-the-Job Training (OJT), which serves as a practical platform for participants to gain real-world experience in their field of study."
    },
    {
      question: "Can I access course materials after completing the course?",
      answer: "You will receive full access to all course materials for one year, including any updates or enhancements made during this period."
    },
    {
      question: "Who is eligible for these courses?",
      answer: "Our courses are thoughtfully designed to cater to students, professionals, and individuals eager to enhance their skills in software development."
    },
    {
      question: "When are the live classes held?",
      answer: "Live sessions are typically held as per the requirement of the batch."
    },
    {
      question: "Does TREx give certificates?",
      answer: "Yes, TREx provides certificates upon successfully completing the curriculum."
    },
  ];

  

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>FREQUENTLY ASKED QUESTIONS</h2>
      <p>We're happy to answer your questions</p>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">
            {faq.question}
            <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
          </div>
          <div className="faq-answer" style={{ display: activeIndex === index ? 'block' : 'none' }}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

const Home = ({ onScrollToPricing }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [isFloatingBtnExpanded, setIsFloatingBtnExpanded] = useState(false);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000); 

    return () => clearTimeout(timer); 
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false); 
  };



  const handleApplyClick = () => {
    navigate('/course-details?plan=0');
  };

  const handleEnquireClick = () => {
    setShowChatPopup(true); 
  };

  const handleCloseChatPopup = () => {
    setShowChatPopup(false);
  };

  const toggleFloatingButton = () => {
    setIsFloatingBtnExpanded((prev) => !prev);
    if (!isFloatingBtnExpanded) {
      handleEnquireClick();
    }
  };

  return (
    <div className="home-container">
      {showPopup && <Popup onClose={handleClosePopup} />}
      <ChatPopup isOpen={showChatPopup} onClose={handleCloseChatPopup} />
      <section className="top-section">
        <div className="top-section-left">
          <div className="camp-heading">
            <img src={monitor} alt="Logo" />
            <span className="camp-heading-text">Elite Workforce Development Program</span>
          </div>
          <h1 className="main-heading">
            ATTAIN JOB-READY EXPERTISE TO THRIVE IN THE <span className="highlight">COMPETITIVE MARKET</span>
          </h1>
          <p className="subtext">
            We Train People to be Professionals.<br />
            Become job-ready with projects tailored to industry standards and expectations.
          </p>
          <button className="applys-btn" onClick={handleApplyClick}>Apply Now</button>
        </div>
        <div className="top-section-right">
          <iframe
            src="https://lottie.host/embed/439ef904-0a95-4036-a679-a01a3a637f43/yRincCchTf.json"
            style={{ border: 'none', width: '650px', height: '600px' }}
            title="Lottie Animation"
          ></iframe>
        </div>
      </section>

      <section className="hero-section">
        <div className="hero-text-box">
          <h1>Bridging Knowledge and Practice for Future Leaders</h1>
          <p>At our core, we connect classroom learning with real-world applications, shaping the next generation of industry leaders.</p>
          <p>Our hands-on training programs empower people to develop essential skills, drive innovation, and excel in their fields.</p>
          <p>Join us to transform your potential into impactful leadership</p>
        </div>
      </section>

      <CarouselComponent />

      <TestSeriesSection onScrollToPricing={onScrollToPricing} />
      <CollaboratorsSection />

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }} className="statistics-section">
        <div className="stat-card">
          <img src={reading} alt="Registered Students Icon" className="stat-icon" />
          <div className="stat-info">
            <h3><CountUp end={1.5} decimals={1} suffix=" K" duration={30} /></h3>
            <p>Registered Students</p>
          </div>
        </div>
        <div className="stat-card">
          <img src={check} alt="Onboarded Students Icon" className="stat-icon" />
          <div className="stat-info">
            <h3><CountUp end={554} duration={35} /></h3>
            <p>Student Onboarded</p>
          </div>
        </div>
        <div className="stat-card">
          <img src={list} alt="Tests Attempted Icon" className="stat-icon" />
          <div className="stat-info">
            <h3><CountUp end={414} duration={35} /></h3>
            <p>Tests Attempted</p>
          </div>
        </div>
        <div className="stat-card">
          <img src={clas} alt="Classes Attended Icon" className="stat-icon" />
          <div className="stat-info">
            <h3><CountUp end={1.31} decimals={2} suffix=" K" duration={15} /></h3>
            <p>Classes Attended</p>
          </div>
        </div>
      </motion.div>

      <div className="whatsapp-community-section">
        <h4>Join Our whatsapp Community for Placement Updates & Important Resources</h4>
        <p>Get Updates for: Latest Job opportunities in Big 4s & Top Firms, Important Resources for Interview Preparation, Updates from the Finance World.</p>
        <a href="https://chat.whatsapp.com/LQb2ZO8vlrt73TIbGnqzpQ" target="_blank" rel="noopener noreferrer" className="join-whatsapp-btn">
          JOIN WHATSAPP COMMUNITY
        </a>
      </div>

      <ProgramSection />

      <div className="about-section">
        <div className="about-header">ABOUT US</div>
        <div className="about-content">
          <Typography variant="h3">About Us</Typography>
          <p>At Saanvi Technology Solutions (sts), we bridge the gap between education and employment by offering hands-on, on-the-job experience. Whether you're a student, a recent graduate, or someone looking to pivot careers, our platform connects you with industry leaders ready to mentor and guide you.</p>
          <p>Take the first step toward your dream career by gaining the skills and experience that employers value most. Start your journey today!</p>
        </div>
        <a href="https://saanvitechs.com/about.php" target="_blank" rel="noopener noreferrer" className="read-more-btn">Read More</a>
      </div>

      <ReviewCarousel />
      <FAQSection />

      <div
        className={`floating-btn ${isFloatingBtnExpanded ? 'expanded' : ''}`}
        onClick={toggleFloatingButton}
      >
        <img src={enquireLogo} alt="Enquire Logo" className="enquire-logo" />
        <span>Inquire</span>
      </div>
    </div>
  );
};

export default Home;

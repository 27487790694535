import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup, Typography } from '@mui/material';
import ITCareerSteps from './Knowmore'; 
import ExecutivePlan from './ExecutivePlan';
import Apprenticeship from './Apprenticeship';
import SosPlan from './SosPlan';
import JavaBlitz from './JavaBlitz'; // Import JavaBlitz component
import './CourseDetails.css'; 

const CourseDetails = ({ planIndex }) => {
  const location = useLocation();
  const [selectedPlan, setSelectedPlan] = useState('Nothing'); 

  useEffect(() => {
    const params = new URLSearchParams(location.search);  
    const planIndex = params.get('plan');  

    console.log("Plan Index:", planIndex);
    if (planIndex) {
      switch (planIndex) {
        case '0':
          setSelectedPlan('titan-plan');
          break;
        case '1':
          setSelectedPlan('executive-plan');
          break;
        case '2':
          setSelectedPlan('apprentice-plan');
          break;
        case '3':
          setSelectedPlan('sos-plan');
          break;
        case '4': // Added case for Java Blitz
          setSelectedPlan('java-blitz');
          break;
        default:
          setSelectedPlan('Nothing');
      }
    }
  }, [planIndex]);

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <div className="course-details-container">
      <Typography variant="h3" style={{ marginBottom: '20px' }}>
        Course Description
      </Typography>

      <ButtonGroup variant="outlined" className="plan-buttons">
        <Button
          onClick={() => handlePlanChange('titan-plan')}
          className={selectedPlan === 'titan-plan' ? 'selected' : ''}
        >
          Titan Plan
        </Button>
        <Button
          onClick={() => handlePlanChange('executive-plan')}
          className={selectedPlan === 'executive-plan' ? 'selected' : ''}
        >
          Executive Plan
        </Button>
        <Button
          onClick={() => handlePlanChange('apprentice-plan')}
          className={selectedPlan === 'apprentice-plan' ? 'selected' : ''}
        >
          Apprentice Plan
        </Button>
        <Button
          onClick={() => handlePlanChange('sos-plan')}
          className={selectedPlan === 'sos-plan' ? 'selected' : ''}
        >
          SOS Plan
        </Button>
        <Button
          onClick={() => handlePlanChange('java-blitz')} // Java Blitz button logic
          className={selectedPlan === 'java-blitz' ? 'selected' : ''}
        >
          Java Blitz
        </Button>
      </ButtonGroup>

      <div className="plan-content">
        {selectedPlan === 'titan-plan' && <ITCareerSteps />}
        {selectedPlan === 'executive-plan' && <ExecutivePlan />}
        {selectedPlan === 'apprentice-plan' && <Apprenticeship />}
        {selectedPlan === 'sos-plan' && <SosPlan />}
        {selectedPlan === 'java-blitz' && <JavaBlitz />} 
      </div>
    </div>
  );
};

export default CourseDetails;


// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { Button, ButtonGroup, Typography } from '@mui/material';
// import ITCareerSteps from './Knowmore'; 
// import ExecutivePlan from './ExecutivePlan';
// import Apprenticeship from './Apprenticeship';
// import SosPlan from './SosPlan';
// import './CourseDetails.css'; 


// const CourseDetails = ({ planIndex }) => {
//   const location = useLocation();
//   const [selectedPlan, setSelectedPlan] = useState('Nothing'); 
 

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);  
//     const planIndex = params.get('plan');  
  
//     console.log("Plan Index:", planIndex);
//     if (planIndex) {
//       switch (planIndex) {
//         case '0':
//           setSelectedPlan('titan-plan');
//           break;
//         case '1':
//           setSelectedPlan('executive-plan');
//           break;
//         case '2':
//           setSelectedPlan('apprentice-plan');
//           break;
//         case '3':
//           setSelectedPlan('sos-plan');
//           break;
//         default:
//           setSelectedPlan('Nothing');
//       }
//     }
//   }, [planIndex]);
//   //  }, [location.search, planIndex]);


//   const handlePlanChange = (plan) => {
//     setSelectedPlan(plan);
//   };

//   return (
//     <div className="course-details-container">
//       <Typography variant="h3" style={{ marginBottom: '20px' }}>
//         Course Description
//       </Typography>

//       <ButtonGroup variant="outlined" className="plan-buttons">
//         <Button
//           onClick={() => handlePlanChange('titan-plan')}
//           className={selectedPlan === 'titan-plan' ? 'selected' : ''}
//         >
//           Titan Plan
//         </Button>
//         <Button
//           onClick={() => handlePlanChange('executive-plan')}
//           className={selectedPlan === 'executive-plan' ? 'selected' : ''}
//         >
//           Executive Plan
//         </Button>
//         <Button
//           onClick={() => handlePlanChange('apprentice-plan')}
//           className={selectedPlan === 'apprentice-plan' ? 'selected' : ''}
//         >
//           Apprentice Plan
//         </Button>
//         <Button
//           onClick={() => handlePlanChange('sos-plan')}
//           className={selectedPlan === 'sos-plan' ? 'selected' : ''}
//         >
//           SOS Plan
//         </Button>
//       </ButtonGroup>

//       <div className="plan-content">
//         {selectedPlan === 'titan-plan' && <ITCareerSteps />}
//       </div>
//       <div className="plan-content">
//         {selectedPlan === 'executive-plan' && <ExecutivePlan />}
//       </div>
//       <div className="plan-content">
//         {selectedPlan === 'apprentice-plan' && <Apprenticeship />}
//       </div>
//       <div className="plan-content">
//         {selectedPlan === 'sos-plan' && <SosPlan />}
//       </div>
//     </div>
//   );
// };

// export default CourseDetails;

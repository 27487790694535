import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './CarouselComponent.css';
import spring from './images/spring.png';
import reactjs from './images/react.png';
import Git from './images/git.png';
import jenkins from './images/Jen.png';
import kanban from './images/kan.png';
import Kubernates from './images/kube.png';
import Datadog from './images/datadog.png';
import Azure from './images/Azure.png';
import Postman from './images/postman .png';

const imageDetails = [
  { src: spring, name: 'Spring', description: 'Spring Framework for Java.' },
  { src: reactjs, name: 'React', description: 'ReactJS for frontend development.' },
  { src: Git, name: 'Git', description: 'Version control with Git.' },
  { src: jenkins, name: 'Jenkins', description: 'CI/CD with Jenkins.' },
  { src: kanban, name: 'Kanban', description: 'For agile management.' },
  { src: Kubernates, name: 'Kubernetes', description: 'Container orchestration.' },
  { src: Datadog, name: 'Datadog', description: 'Monitoring with Datadog.' },
  { src: Azure, name: 'Azure', description: 'Azure cloud platform.' },
  { src: Postman, name: 'Postman', description: 'API testing with Postman.' },
];



const CarouselComponent = () => {
  const [flipped, setFlipped] = useState(Array(imageDetails.length).fill(false));
  const swiperRef = useRef(null);

  const handleFlip = (index) => {
    setFlipped((prevFlipped) => {
      const newFlipped = [...prevFlipped];
      newFlipped[index] = !newFlipped[index];

      if (newFlipped.some((isFlipped) => isFlipped)) {
        swiperRef.current?.autoplay.stop();
      } else {
        swiperRef.current?.autoplay.start();
      }

      return newFlipped;
    });
  };

  return (
    <div className="carousel-container">
      <h2 className="carousel-heading">Gain Mastery In Trending Industrial Tools</h2>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]} 
        spaceBetween={10} // Use smaller space to fit more slides
        slidesPerView={3}  // Default for mobile       
        breakpoints={{
          640: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 3, spaceBetween: 1 },
          1024: { slidesPerView: 3, spaceBetween: 30 },
          1280: { slidesPerView: 6, spaceBetween: 20 },
        }}
        loop={true}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {imageDetails.map((image, index) => (
          <SwiperSlide key={index}>
            <div
              className={`image-box ${flipped[index] ? 'flipped' : ''}`}
              onClick={() => handleFlip(index)}
            >
              <div className="card-front">
                <img
                  src={image.src}
                  alt={`Slide ${index + 1}`}
                  className="carousel-image"
                />
              </div>

              <div className="card-back">
                <h3>{image.name}</h3>
                <p>{image.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselComponent;

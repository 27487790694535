import React, { useState, useEffect } from 'react'; // Import useEffect
import { Typography, Button, Card, CardContent, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faCircleLeft,faCircleRight } from '@fortawesome/free-solid-svg-icons';
import './JavaBlitz.css';

const StepContent = ({ stepNumber, stepTitle, stepDescription, lessons }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsPerView, setCardsPerView] = useState(3); // Initialize the state with 3 cards per view for desktop
  const [expandedDescription, setExpandedDescription] = useState(false); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Line 14

  // Adjust cards per view based on screen width
  const updateCardsPerView = () => { // Line 18
    const isMobileView = window.innerWidth <= 768;
    setIsMobile(isMobileView);
    if (isMobileView) {
      setCardsPerView(1); // Show 1 card at a time on mobile
    } else {
      setCardsPerView(3); // Show 3 cards on desktop
    }
  };
  

  useEffect(() => {
    updateCardsPerView(); // Set the initial value
    window.addEventListener('resize', updateCardsPerView); // Listen to window resize
    
    return () => {
      window.removeEventListener('resize', updateCardsPerView); // Clean up listener
    };
  }, []);

  const handleNext = () => {
    if (currentIndex < lessons.length - cardsPerView) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const toggleReadMoreDescription = () => {
    setExpandedDescription(!expandedDescription); // Toggle expanded state
  };
  const showArrows = isMobile ? lessons.length > 1 : lessons.length > 2; // Line 47

  return (
    <div className="step">
      <div className="step-number">{stepNumber}</div>
      <div className="step-content">
        {/* Box for Step Title and Description */}
        <Card className="description-card">
        <CardContent>
            <Typography variant="h5" className="step-title3">{stepTitle}</Typography>

            {/* Step Description with Read More functionality */}
            <Typography
              variant="body1"
              className={`step-description3 ${expandedDescription ? 'expanded' : ''}`}
            >
              {stepDescription}
            </Typography>

            {/* Show Read More/Read Less button based on expanded state */}
            {stepDescription.length > 100 && (
               <button
               className="read-more-link"
               onClick={toggleReadMoreDescription}
             >
               {expandedDescription ? 'Read Less' : 'Read More'}
             </button>
            )}
          </CardContent>
        </Card>

        {/* Lesson Tiles with Navigation */}
        <div className="lesson-carousel">
        {showArrows && ( // Line 54
              <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
             </IconButton>
             )}

          <div className="lesson-wrapper">
            {lessons.slice(currentIndex, currentIndex + cardsPerView).map((lesson) => (
              <Card key={lesson.id} className="lesson-card3">
                <CardContent>

                  {/* Add Font Awesome Book Open Icon and "Lesson no" aligned to the left */}
                  <div className="lesson-header">
                    <FontAwesomeIcon icon={faBookOpen} size="lg" style={{ color: '#ff6b6b' }} />
                    <Typography variant="body2" className="lesson-number">Lesson {lesson.id}</Typography>
                  </div>

                  {/* Lesson Title */}
                  <Typography variant="h6" className="lesson-title3">{lesson.title}</Typography>

                  {/* Lesson Description */}
                  <Typography variant="body2" className="lesson-description3">
                    {lesson.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
          {showArrows && ( // Line 73
  <IconButton onClick={handleNext} disabled={currentIndex >= lessons.length - cardsPerView}>
  </IconButton>
)}

        </div>
      </div>
    </div>
  );
};

const SosPlan = () => {
  const lessonsStep1 = [
   ];
  const lessonsStep2 = [
    ];

  const lessonsStep3 = [
    ];

  const lessonsStep4 = [
  ];

 

  return (
    <div className="wrapper">
      <div className="left-section">
        <div className="header-section">
          <Typography variant="h3" className="main-title">
          Emergency Career Boost  to Reignite Your IT Journey
          </Typography>
          <div className="button-group3">
            <Button variant="contained" color="primary" className="main-btn3"  onClick={() => window.open('https://forms.gle/QWkHboXvXsPSfmWJ8', '_blank')}>
              Enroll Now
            </Button>
            {/* <Button variant="outlined" color="primary" className="main-btn3"  onClick={() => window.open('https://forms.gle/PGrDh2pKSKEXjTud6', '_blank')}>
              Book a Free Demo Class
            </Button> */}
          </div>
          <div style={{ marginTop: '20px' }}>
            <iframe
              src="https://lottie.host/embed/a54f37db-99cd-4c6e-8323-f9b8fddb7a75/Jeba7Teos8.json"
              style={{ border: 'none', width: '100%', height: '400px' }}
              title="Lottie Animation"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="right-section">
        <div className="step-wrapper">
          <StepContent
            stepNumber="1"
            stepTitle="STAGE 1: CORE JAVA"
            stepDescription="In the first stage of Core Java, you'll focus on mastering programming basics, including Java syntax, data types, operators, and control statements like loops and conditionals. By the end, you'll confidently write, debug, and execute Java programs."
            lessons={lessonsStep1}
          />
           <StepContent
            stepNumber="2"
            stepTitle="STAGE 2: OOPS CONCEPTS"
            stepDescription="In this stage we focuses on object-oriented programming (OOP) concepts. You'll learn about classes, objects, inheritance, polymorphism, encapsulation, and abstraction, enabling you to design and implement reusable, modular code. By the end, you'll effectively apply OOP principles in Java development. "
            lessons={lessonsStep2}
          />
                    <StepContent
            stepNumber="3"
            stepTitle="STAGE 3: COLLECTION FRAMEWORK "
            stepDescription="Stage 3 introduces the Collection Framework, where you'll explore classes like ArrayList, HashSet, and HashMap. You'll learn to manage and manipulate groups of objects efficiently, enabling you to handle dynamic data structures. By the end, you'll confidently use collections in real-world Java applications."
            lessons={lessonsStep3}
          />
        </div>
      </div>
    </div>
  );
};

export default SosPlan;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Popup.css';
import imageSrc from '../components/javablitz.png';

const Popup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleApplyNowClick = () => {
    navigate('/course-details?plan=4'); // Navigate to the Java Blitz plan
  };
  return (
    <div className="custom-popup-overlay">
      <div className="custom-popup-content">
        <button className="custom-close-icon" onClick={onClose}>&times;</button>
        <img src={imageSrc} alt="Popup Content" className="custom-popup-image" />
        <button 
          className="custom-apply-now-button" 
          onClick={handleApplyNowClick}
        >
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default Popup;

import React, { useState } from 'react';
import axios from 'axios';
import './ChatPopup.css';

const ChatPopup = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    qualification: '',
    description: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API_URL = 'https://backend-production-7278.up.railway.app/api/enquiries';

      const response = await axios.post(API_URL, {
        name: formData.fullName,
        phoneNumber: formData.phone,
        email: formData.email,
        description: formData.description
      });

      console.log('Enquiry submitted successfully:', response.data);
      setIsSubmitted(true);

      setFormData({
        fullName: '',
        email: '',
        phone: '',
        qualification: '',
        description: ''
      });

    } catch (error) {
      console.error('Failed to submit enquiry:', error.response?.data || error.message);
    }
  };

  const handleClose = () => {
    setIsSubmitted(false); 
    onClose(); 
  };

  if (!isOpen) return null;

  return (
    <div className="chat-popup-container">
      <div className="chat-popup">
        <div className="chat-header">
          <h2>TREx Assistant</h2>
          <p>Welcome to Trex chat support. How can we help you?</p>
          <button className="chat-close-btn" onClick={handleClose}>
            &times;
          </button>
        </div>
        {!isSubmitted ? (
          <form className="chat-form" onSubmit={handleSubmit}>
            <label>
              Full Name <span className="required">*</span>
              <div className="input-icon">
                <i className="fas fa-user"></i>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                  aria-label="Full Name"
                />
              </div>
            </label>
            <label>
              Email <span className="required">*</span>
              <div className="input-icon">
                <i className="fas fa-envelope"></i>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  aria-label="Email Address"
                />
              </div>
            </label>
            <label>
              Phone Number <span className="required">*</span>
              <div className="input-icon">
                <i className="fas fa-phone"></i>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  aria-label="Phone Number"
                />
              </div>
            </label>
            <label>
              Qualification
              <input
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleInputChange}
                aria-label="Qualification"
              />
            </label>
            <label>
              Description
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows="3"
                required
                aria-label="Description"
              ></textarea>
            </label>
            <button type="submit" className="chat-submit-btn">
              Submit
            </button>
          </form>
        ) : (
          <div className="confirmation-message">
            <p>Thank you for contacting TREx. We will contact you soon on the details you have provided.</p>
            <button className="chat-submit-btn" onClick={handleClose}>
              OK
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPopup;
